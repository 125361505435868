import React from 'react';
import { IC, ui } from './ui';

function IconsUI({
  info,
  clsnm = '',
  click = null,
  tooltip = '',
  link = null,
  linkTargetToBlank = false,
  linkAriaLabel = null,
  linkRel = 'noreferrer',
  linkClass = '',
}) {
  const { icon } = info;

  const newProps = {
    className: clsnm,
    onClick: click,
    'data-toggle': tooltip ? 'tooltip' : null,
    'data-placement': tooltip ? 'top' : null,
    title: tooltip,
    'aria-label': tooltip,
  };
  const newIcon = React.cloneElement(icon, newProps);

  if (link) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={link}
        target={linkTargetToBlank ? '_blank' : '_self'}
        rel={linkTargetToBlank === true ? 'noreferrer' : linkRel}
        aria-label={linkAriaLabel || tooltip || 'link'}
        className={linkClass}
      >
        {newIcon}
      </a>
    );
  }
  return newIcon;
}

function fileIcon(type) {
  if (type.includes('image'))
    return IconsUI({ info: ui.file.image, clsnm: 'svg09 cGreen' });
  if (type.includes('pdf'))
    return IconsUI({ info: ui.file.pdf, clsnm: 'svg09 cRed' });
  return IconsUI({ info: ui.file.other, clsnm: 'svg09 cBlue' });
}

export { IconsUI, IC, ui, fileIcon };
