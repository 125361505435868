import { useContext } from 'react';
import { LinkButton, ThemeSwitch } from '../wookie';
import { objLinks, images, tenant } from '../../config';
import PackageContext  from '../../context/PackageContext';
import '../../assets/css/app/nav.css';

export default function NavBar() {
  const { theme, changeTheme } = useContext(PackageContext);

  return (
    <nav className='navbar100'>
      <div className='navbar'>
        <>
            <a href="/"
              title={tenant}
              rel='noreferrer'
              aria-label={tenant}
            >
          <div className='navbarLogo'>
            
            <img
              src={images[theme].logotext}
              className='navLogo logoTXT'
              title={tenant}
              alt={`${tenant} logo`}
            />
            <img
              src={images[theme].logo}
              className='navLogo logoXS'
              title={tenant}
              alt={`${tenant} logo`}
            />
          </div>
            </a>
        </>
        <div className='d-flex flex-row align-items-center'>
          <div className='d-flex flex-row align-items-center'>
            <div className='d-flex flex-row justify-content-center align-items-center navUL'>
              <span className='divShow'>
                <LinkButton item={objLinks.simule} />
                <LinkButton item={objLinks.whatsapp} />
                <LinkButton item={objLinks.app} />
                <ThemeSwitch
                  drop={true}
                  globalTheme={changeTheme}
                />
              </span>

            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
