import { useCallback, useEffect, useRef, useState } from 'react';
import  CardBox from './CardBox';
import '../../../assets/css/components/cardboxline.css';

export default function CardBoxLine({
  cards = { cardWidth: 250, items: [], use: '' },
}) {
  const [columns, setColumns] = useState(1);
  const sectionRef = useRef(null);

  const updateColumns = useCallback(() => {
    const sectionWidth = sectionRef.current?.offsetWidth || 0;
    const cols = Math.floor(sectionWidth / cards.cardWidth);
    setColumns(cols);
  }, [cards]);

  useEffect(() => {
    updateColumns();
    window.addEventListener('resize', updateColumns);
    return () => window.removeEventListener('resize', updateColumns);
  }, [updateColumns]);

  return (
    <section
      className='setWidth mAuto cardboxline'
      ref={sectionRef}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
    >
      {cards.items.map((card, index) => (
        <CardBox
          key={`${cards.use}cardBoxLine_${index}`}
          {...card}
        />
      ))}
    </section>
  );
}
