import '../../../assets/css/components/formheader.css';

export default function FormHeader({ logo, formname, clsnm = '' }) {
  return (
    <>
      <a
        href='/'
        rel='noreferrer'
        target='_blank'
        className={`${clsnm}`}
      >
        <img
          src={logo}
          className='formLogoHeader'
          alt='logo'          
        />
      </a>
      <h1 className={`mt-5 mb-3 fw600 fs26 text-center`}>{formname}</h1>
    </>
  );
}
