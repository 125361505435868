import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../../../assets/css/components/boximagetext.css';

export default function BoxTextImageInside({ image = null, text = 'texto' }) {
  return (
    <section className={`setWidth mAuto py-5 px-1 boxTextImageInside gocol600`}>
      <div className='boxTextText'>{text}</div>
      <div className='boxTextImage'>
        <LazyLoadImage
          src={image}
          alt='foto'
        />
      </div>
    </section>
  );
}
