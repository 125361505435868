import { LinkButton, IconsUI, ui } from '../wookie';
import { objLinks,setup } from '../../config';
import '../../assets/css/app/footer.css';

export default function Footer() {
  const y = new Date().getFullYear();

  return (
    <footer>
      <div className='footer fs10'>
        <div className='d-flex flex-row justify-content-center align-items-baseline'>
          <span className='footer-sp-fale'>Fale conosco: </span>
          <LinkButton item={objLinks.instagram} />
          <LinkButton item={objLinks.whatsapp} />
          <LinkButton item={objLinks.mail} />
          <LinkButton item={objLinks.app} />
        </div>
        <span className='text-center footer-sp-reg'>
          {setup.title} <IconsUI info={ui.misc.registered} /> Todos
          os direitos reservados{' '}
          <IconsUI info={ui.misc.copyright} /> {y}
        </span>
      </div>
    </footer>
  );
}
