import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet  } from 'react-helmet-async';
import PackageContext from './PackageContext';
import CONF from '../config';

export default function PackageProvider({ children }) {
  const navigate = useNavigate();
  const [theme, setTheme] = useState('normal');

  const changeTheme = useCallback((th) => {
    document.documentElement.className = th;
    localStorage.setItem('theme', th);
    setTheme(th);
  }, []);

  function SEO({ title = null, description = null, name = null, type = null }) {
    const setMeta = () => {
      return {
        type: type || CONF.meta.type,
        description: description || CONF.meta.description,
        keywords: CONF.meta.keywords,
        author:name || CONF.meta.author,
        open: {
          title: CONF.meta.title,
          description: description || CONF.meta.description,
          image: CONF.meta.imageLink,
          imageWidth: CONF.meta.imageWidth,
          imageHeight: CONF.meta.imageHeight,
          imageType: CONF.meta.imageType,
          url: `https://www.${CONF.site}`,
          type: type || 'website',
        },
        twitter: {
          card: type || 'summary_large_image',
          title: CONF.meta.title,
          description: description || CONF.meta.description,
          image: CONF.meta.imageLink,
        },
        robots: CONF.meta.robots,
      };
    };

    const META = setMeta();
    const currentYear = new Date().getFullYear();

    const metaTags = [
      { name: 'description', content: META.description },
      { name: 'keywords', content: META.keywords },
      { name: 'author', content: META.author },
      { name: 'publisher', content: META.author },
      { name: 'robots', content: META.robots },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'googlebot', content: 'index, follow' },
      { name: 'rating', content: 'general' },
      { name: 'revisit-after', content: '1 days' },
      { name: 'language', content: 'pt-BR' },
      { name: 'country', content: 'Brazil' },
      { name: 'geo.region', content: 'BR-SP' },
      { name: 'copyright', content: `${currentYear} ${CONF.tenant}` },
      {
        name: 'robots',
        content:
          'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
      },
      { name: 'Content-Type', content: 'text/html; charset=utf-8' },
    ];

    const twitterTags = [
      { name: 'twitter:card', content: META.twitter.card },
      { name: 'twitter:title', content: META.twitter.title },
      { name: 'twitter:description', content: META.twitter.description },
      { name: 'twitter:image', content: META.twitter.image },
    ];

    const ogTags = [
      { property: 'og:title', content: META.open.title },
      { property: 'og:description', content: META.open.description },
      { property: 'og:image', content: META.open.image },
      { property: 'og:image:width', content: META.open.imageWidth },
      { property: 'og:image:height', content: META.open.imageHeight },
      { property: 'og:image:type', content: META.open.imageType },
      { property: 'og:url', content: META.open.url },
      { property: 'og:type', content: META.open.type },
      { property: 'og:locale', content: 'pt_BR' },
      { property: 'og:site_name', content: CONF.tenant },
    ];

    return (
      <Helmet>
        <title>{title || META.title}</title>
        <link rel="canonical" href={META.open.url} />
        <link rel="icon" type="image/x-icon" href={CONF.favicon} />
        <link rel="apple-touch-icon" type="image/x-icon" href={`https://www.${CONF.site}/img/brand/${CONF.brand}/logo512.png`} />
        {metaTags.map((tag) => (
          <meta
            key={tag.name}
            name={tag.name}
            content={tag.content}
          />
        ))}
        {ogTags.map((tag) => (
          <meta
            key={tag.property}
            property={tag.property}
            content={tag.content}
          />
        ))}
        {twitterTags.map((tag) => (
          <meta
            key={tag.name}
            name={tag.name}
            content={tag.content}
          />
        ))}
      </Helmet>
    );
  }

  useEffect(() => {
    const setMeta = () => {
      return {
        type: CONF.meta.type,
        description: CONF.meta.description,
        keywords: CONF.meta.keywords,
        author: CONF.meta.author,
        open: {
          title: CONF.meta.title,
          description: CONF.meta.description,
          image: CONF.meta.imageLink,
          imageWidth: CONF.meta.imageWidth,
          imageHeight: CONF.meta.imageHeight,
          imageType: CONF.meta.imageType,
          url: `https://www.${CONF.domain}`,
          type: 'website',
        },
        twitter: {
          card: 'summary_large_image',
          title: CONF.meta.title,
          description: CONF.meta.description,
          image: CONF.meta.imageLink,
        },
        robots: CONF.meta.robots,
      };
    };

    const META = setMeta();

    const defineSchema = (META) => {
      const createSchema = (schema) => {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'application/ld+json';
        scriptTag.text = JSON.stringify(schema);
        document.head.appendChild(scriptTag);
        return () => {
          document.head.removeChild(scriptTag);
        };
      };

      const jsonLd = {
        '@context': 'https://schema.org',
        '@type': META.type || 'Organization',
        name: `${CONF.tenant} ${CONF.headline}`,
        alternateName: CONF.tenant,
        description: CONF.description,
        image: CONF.links.logo,
        url: CONF.links.site,
        email: CONF.setup.email,
        telephone: `+55${CONF.setup.phone}`,
        areaServed: 'BR',
        brand: CONF.brand,
        slogan: CONF.headline,
        hasCertification: {
          '@type': 'Certification',
          name: 'CA-300',
          description: 'Certificação de Agente de Crédito',
          issuedBy: {
            '@type': 'Organization',
            name: 'ABECIP',
            url: 'https://www.abecip.org.br/',
          },
        },
        address: {
          '@type': 'PostalAddress',
          addressCountry: 'BR',
          addressLocality: 'Taubaté-SP',
          addressRegion: 'SP',
          postalCode: '12000-000',
          streetAddress: 'Centro',
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: 99,
          bestRating: 100,
          ratingCount: 5891,
        },
      };

      return createSchema(jsonLd);
    };

    const savedTheme = localStorage.getItem('theme') || 'normal';
    changeTheme(savedTheme);
    defineSchema(META);
  }, [theme, changeTheme]);

  const context = {
    navigate,
    theme,
    setTheme,
    changeTheme,
    SEO,
  };

  return (
    <PackageContext.Provider value={context}>
      <HelmetProvider>
          {children}
      </HelmetProvider>
    </PackageContext.Provider>
  );
}
