import { api, end } from '../config/imports';

async function createLead({name, email, phone, message}) {
  const data = {
    name,
    email,
    phone,
    message,
  };

  const response = await api.post(end.lead.create, data);
  return response.data;
}

export { createLead };
