import { useEffect, useState } from 'react';
import '../../../assets/css/components/hero.css';
import { FaleConosco, FaleConoscoForm } from '..';

export default function Hero({
  clsnmbg = '',
  clsnm = '',
  overlay = 'bgOverlay',
  applyWidth = 'w70 mAuto',
  slogan = 'Seu slogan',
  sloganClass = '',
  sloganSubText = '',
  sloganSubTextClass = '',
  formOnlyBigScreen = true,
  form = true,
  formConf = null,
  formCreateLead = null,
  formTitle = 'Fale conosco agora',
  formClass = 'wpMax500',
  formColorMode = 'dark',
  id = 'hero',
  zoomSlogan = false,
}) {
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth);

  useEffect(() => {
    const heroSection = document.getElementById(`hero_sec_${id}`);
    heroSection.classList = `${clsnmbg || ''} ${clsnm || ''}`;

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setDisplayWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formOrButton = () => {
    if (formOnlyBigScreen && displayWidth <= 768) {
      return (
        <FaleConosco
          typeButton={true}
          textButton='Fale agora com um especialista'
          pClass='fs24 fw700 cWhite'

          formConf={formConf}
          formCreateLead={formCreateLead}
          formColorMode={formColorMode}
          formTitle={formTitle}
          formClass={formClass}
        />
      );
    }

    if (form) {
      return (
        <FaleConoscoForm
          CONF={formConf}
          createLead={formCreateLead}
          clsnmCallForm={formClass}
          title={formTitle}
          colorMode={formColorMode}
        />
      );
    }
  }

  return (
    <section
      id={`hero_sec_${id}`}
      className={`${clsnm}`}
    >
      <div className={`${overlay}`}>
        <div className={applyWidth}>
          <div className='d-flex flex-row justify-content-around gocol750'>
            <div
              className={`d-flex flex-column justify-content-center align-items-center p-5 w50 go100W750 fs54 fw700 cWhite ${
                zoomSlogan ? 'zoomInOut11' : ''
              } `}
            >
              <h1 className={sloganClass}>{slogan}</h1>
              <h2 className={sloganSubTextClass}>{sloganSubText}</h2>
            </div>

            { formOrButton() }
          </div>
        </div>
      </div>
    </section>
  );
}
