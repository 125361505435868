import { useContext } from 'react';
import { IconsUI, ui } from '../icons';
import PackageContext from '../../../context/PackageContext';

export default function NavReturn({ info = '', className = '' }) {
  const { navigate } = useContext(PackageContext);

  return (
    <div
      className={`w100 d-flex flex-row justify-content-end cLink ${className}`}
    >
      <h4 className='mt-2 mb-3'>{info}</h4>
      <p
        className='d-flex flex-row align-items-center hovGreen'
        onClick={() => {
          navigate(-1);
        }}
      >
        <span className={`me-2 fs12 ffSoft`}>
          Voltar
        </span>
        <IconsUI
          info={ui.action.back}
          clsnm={`hovSvgSoft`}
        />
      </p>
    </div>
  );
}
