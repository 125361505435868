import React from 'react';
import { Footer, NavBar } from '../config/imports';
import { company, site, setup } from '../config';
import { ContainerBox } from '../components/wookie';

export default function Privacidade() {
  return (
    <div>
      <ContainerBox
        main={
          <div className=''>
            <div className='setWidth p-3'>
              <div className='p-5'>
                <h4 className='my-5 textContrastColor fw600 '>
                  Termos de uso e Privacidade
                </h4>

                <h5 className='mt-5 mb-3 textContrastColor'>
                  Proteção de Dados e LGPD
                </h5>

                <p>
                  Na <b>{company}</b>, tratamos sua privacidade com
                  seriedade. As informações fornecidas serão usadas
                  exclusivamente para atender à sua solicitação de financiamento
                  imobiliário e serão armazenadas apenas pelo tempo necessário.
                  Em conformidade com a LGPD, você pode solicitar a exclusão dos
                  seus dados a qualquer momento.
                </p>

                <h5 className='mt-5 mb-3 textContrastColor'>Introdução</h5>

                <p>
                  A <b>{company}</b> está comprometida em proteger a
                  privacidade e os dados pessoais de seus usuários. Esta
                  Política de Privacidade esclarece como coletamos, usamos,
                  armazenamos e protegemos as informações dos visitantes do
                  nosso site, conforme exigido pela Lei Geral de Proteção de
                  Dados (LGPD - Lei nº 13.709/2018).
                </p>

                <h5 className='mt-5 mb-3 textContrastColor'>
                  Coleta e Tratamento de Dados
                </h5>

                <p>
                  Coletamos dados pessoais fornecidos <b>voluntariamente</b>{' '}
                  pelos usuários, como nome, e-mail e telefone, e outros que
                  sejam enviados através de formulários de contato ou ao se
                  cadastrar em nossa newsletter. Esses dados são utilizados para
                  responder às suas solicitações, oferecer conteúdos relevantes
                  e melhorar nossos serviços.
                </p>

                <p>
                  Dados técnicos como endereço IP, tipo de navegador e tempo de
                  visita também podem ser coletados automaticamente para fins
                  estatísticos e de segurança.
                </p>

                <p>
                  Além disso, caso você solicite uma simulação de financiamento
                  imobiliário, ao enviar o formulário de simulação, você
                  concorda em compartilhar seus dados com a{' '}
                  <b>{company}</b> e com as instituições financeiras
                  parceiras, para que possamos oferecer a melhor proposta de
                  crédito imobiliário para você.
                </p>

                <p>
                  No entanto, antes que seus dados sejam encaminhados para
                  qualquer banco, ou que qualquer proposta seja realizada,{' '}
                  <b>vamos entrar em contato primeiramente com você</b>, para
                  explicar todo o processo, e caso você tenha interesse, então
                  neste momento seguiremos com as propostas junto aos bancos.
                </p>

                <h5 className='mt-5 mb-3 textContrastColor'>
                  Finalidade do Tratamento
                </h5>

                <p>
                  Os dados coletados serão utilizados exclusivamente para as
                  finalidades descritas abaixo:
                </p>

                <ul>
                  <li>Responder a consultas enviadas por meio do site;</li>
                  <li>Enviar propostas de financiamento imobiliário;</li>
                  <li>
                    Enviar materiais informativos, com consentimento prévio;
                  </li>
                  <li>Melhorar a experiência do usuário em nosso site;</li>
                  <li>Cumprir obrigações legais e regulatórias.</li>
                </ul>

                <h5 className='mt-5 mb-3 textContrastColor'>
                  Compartilhamento de Dados
                </h5>

                <p>
                  Seus dados pessoais não serão compartilhados com terceiros,
                  exceto em casos de:
                </p>

                <ul>
                  <li>Cumprimento de obrigações legais ou regulatórias;</li>
                  <li>
                    Prestação de serviços por parceiros, sempre respeitando as
                    normas da LGPD;
                  </li>
                  <li>
                    Em caso de determinação judicial ou solicitação por
                    autoridades competentes.
                  </li>
                </ul>

                <h5 className='mt-5 mb-3 textContrastColor'>
                  Segurança dos Dados
                </h5>

                <p>
                  Implementamos medidas técnicas e administrativas para proteger
                  os dados pessoais contra acessos não autorizados, perda
                  acidental ou violações de segurança. Contudo, nenhum sistema é
                  completamente seguro, e em caso de qualquer incidente de
                  segurança que possa comprometer seus dados, notificaremos os
                  usuários e a Autoridade Nacional de Proteção de Dados (ANPD)
                  conforme exigido pela LGPD.
                </p>

                <h5 className='mt-5 mb-3 textContrastColor'>
                  Direitos dos Usuários
                </h5>

                <p>Os usuários têm direito de:</p>

                <ul>
                  <li>
                    Solicitar acesso, correção ou exclusão de seus dados
                    pessoais;
                  </li>
                  <li>Revogar o consentimento para o tratamento de dados;</li>
                  <li>
                    Solicitar a portabilidade de seus dados, conforme previsto
                    pela LGPD;
                  </li>
                  <li>
                    Opôr-se ao tratamento de dados em situações específicas.
                  </li>
                </ul>

                <p>
                  Esses direitos podem ser exercidos através do e-mail{' '}
                  <a href={`mailto:privacidade@${site}`}>
                    privacidade@{site}
                  </a>
                  .
                </p>

                <h5 className='mt-5 mb-3 textContrastColor'>Cookies</h5>

                <p>
                  Nosso site utiliza cookies para otimizar a navegação e
                  personalizar conteúdos. Ao acessar nosso site, você poderá
                  gerenciar suas preferências de cookies ou desativá-los
                  diretamente no seu navegador.
                </p>

                <h5 className='mt-5 mb-3 textContrastColor'>
                  Alterações à Política de Privacidade
                </h5>

                <p>
                  Reservamo-nos o direito de alterar esta Política de
                  Privacidade a qualquer momento, mediante aviso prévio no site.
                  Recomendamos a revisão periódica desta página para se manter
                  atualizado sobre como protegemos suas informações.
                </p>

                <h5 className='mt-5 mb-3 textContrastColor'>Contato</h5>

                <p>
                  Em caso de dúvidas sobre esta Política de Privacidade ou sobre
                  o tratamento de seus dados pessoais, entre em contato conosco
                  através do e-mail{' '}
                  <a href={`mailto:privacidade@${site}`}>
                    privacidade@{site}
                  </a>
                  .
                </p>

                <p>
                  Em caso de emergência, você também pode entrar em
                  contato conosco através do telefone{' '}
                  <a href={`tel:+55${setup.phone}`}>{setup.phone_formatted}</a>.
                </p>
              </div>
            </div>
          </div>
        }
        navbar={<NavBar />}
        sidebar={false}
        footer={<Footer />}
        overlayMain={false}
        // classMain='bgsaude'
      />
    </div>
  );
}
