import { useContext } from 'react';
import NavReturn from '../common/NavReturn';
import PackageContext from '../../../context/PackageContext';
import '../../../assets/css/components/containerbox.css';
import CONF from '../../../config';

export default function ContainerBox({
  main,
  navbar = null,
  navreturn = false,
  navreturnInfo = '',
  navreturnClass = '',
  sidebar = null,
  footer = null,
  overlayMain = null,
  classMain = '',
  seoInclude = true,
  seoObject = {},
}) {

const {SEO} = useContext(PackageContext);

  return (
    <div className='containerBox'>
      {seoInclude && (
        <SEO
          title={seoObject.title || CONF.meta.title}
          description={seoObject.description || CONF.meta.description}
          name={seoObject.name || CONF.meta.author}
          type={seoObject.type || 'website'}
        />
      )}
      {navbar}
      {navreturn ? <NavReturn info={navreturnInfo} className={navreturnClass} /> : null}
      <div className={`mainParent ${classMain}`}>
        <main className={overlayMain ? `bgOoverlayDarker` : ''}>
          {sidebar}
          <section
            className={sidebar ? 'containerBoxMainRight' : 'containerBoxMain'}
          >
            {main}
          </section>
        </main>
      </div>
      {footer}
    </div>
  );
}
