import {
  img_budget,
  img_building,
  img_buyhome,
  img_location,
  img_mortgage,
  img_renovation,
  img_transference,
} from '../config/imports';

const classes = {
  main: ' tran03 tGrow105',
  card: 'cardboxMain bgBluerTransp cGray200 hovbgYellow',
  cardWidth: 300,
  header: 'cYellow w100 fs16 fw500 ffSoft p-2',
  footer: 'fs10',
  body: 'p-2',
  text: 'cWhite fs10',
  title: 'fs14',
  subtitle: 'fs07',
  image: '',
};

const cardsDif = {
  use: 'diferenciais',
  cardWidth: 300,
  items: [
    {
      header: 'Custo Zero',
      title: 'Custo Zero para o Cliente',
      maintext:
        'Você não paga nada para fazer o financiamento conosco, nem mesmo a visita de um consultor.',
      classes,
    },
    {
      header: 'Tempo Real',
      title: 'Aplicativo Exclusivo',
      maintext:
        'Acompanhe todo o processo de financiamento em tempo real, com nosso aplicativo exclusivo.',
      classes,
    },
    {
      header: 'Facilidade',
      title: 'Escritórios em diversas cidades',
      maintext:
        'Atendemos em todas as cidades da Região Metropolitana do Vale do Paraíba, Litoral Norte e Serra da Mantiqueira.',
      classes,
    },
    {
      header: 'Especialista',
      title: 'Atendimento Personalizado',
      maintext:
        'Aqui você é atendido por um especialista em financiamento imobiliário, que vai te ajudar a encontrar a melhor solução para o seu caso.',
      classes,
    },
    {
      header: 'Estamos Aqui',
      title: 'Atendimento Todos os Dias',
      maintext:
        'Estamos disponíveis todos os dias da semana, para te atender da melhor forma possível.',
      classes,
    },
    {
      header: 'Experiência',
      title: 'Experiência de Mercado',
      maintext:
        'Com mais de 18 anos de experiência no mercado, estamos preparados para te ajudar a realizar o seu sonho.',
      classes,
    },
    {
      header: 'Segurança',
      title: 'Consultores com Certificação',
      maintext:
        'Nossos consultores são certificados e estão sempre atualizados com as melhores práticas do mercado.',
      classes,
    },
    {
      header: 'Acompanhamento',
      title: 'Acompanhamento Pós-Finanaciamento',
      maintext:
        'Depois que o financiamento é aprovado, você ainda conta com nosso acompanhamento pós-venda, para garantir que tudo saia conforme o planejado.',
      classes,
    },
  ],
};

const textBox = [
  'Com anos de experiência no mercado, construímos uma reputação sólida ao lado de nossos clientes, que sabem que podem contar com transparência e apoio em todas as etapas de seus financiamentos. Nosso compromisso com a clareza e a responsabilidade garante que cada cliente tenha total controle e confiança sobre suas escolhas financeiras, proporcionando uma experiência sem surpresas e sem complicações.',
  'Proporcionamos aos clientes uma jornada de financiamento segura, onde cada detalhe é pensado para atender às suas necessidades e aos seus objetivos. Aqui, cada proposta é elaborada com cuidado, levando em conta o perfil e as condições ideais para cada situação, e sempre com uma comunicação clara e acessível. Sabemos que financiar um bem é uma grande decisão, e é por isso que oferecemos uma gama de opções flexíveis e adaptadas, possibilitando o alcance dos sonhos de nossos clientes sem comprometer a segurança financeira.',
  'Seja para adquirir um imóvel, um veículo ou outros projetos, aqui você encontra as melhores condições, transparência e segurança para dar o próximo passo com confiança.',
];

const classesProd = {
  main: ' tran03 tGrow105 align-items-center   ',
  card: 'cardboxMain bgGray150 hovbgBlue text-center',
  cardWidth: 300,
  header:
    'cBlue w100 fs16 fw500 ffSoft p-2 faCenter',
  footer: 'fs10',
  body: 'cGray800 p-2',
  text: 'fs10',
  title: 'fs14',
  subtitle: 'fs07',
  image: 'wpMax100 my-2',
};

const cardsProds = {
  use: 'produtos',
  cardWidth: 300,
  items: [
    {
      header: 'Aquisição de Imóveis Residenciais',
      title: 'Novos ou Usados',
      image: img_buyhome,
      classes: classesProd,
    },
    {
      header: 'Aquisição de Imóveis Comerciais',
      title: 'Imóveis Comerciais',
      image: img_building,
      classes: classesProd,
    },
    {
      header: 'Aquisição de Terrenos',
      title: 'Terrenos',
      image: img_location,
      classes: classesProd,
    },
    {
      header: 'Aquisição à vista com 100% de FGTS',
      title: 'Sem financiamento',
      image: img_buyhome,
      classes: classesProd,
    },
    {
      header: 'Portabilidade de Crédito Imobiliário',
      title: 'Diminua o valor da sua parcela',
      image: img_transference,
      classes: classesProd,
    },
    {
      header: 'Crédito com garantia de imóvel',
      title: 'Home Equity - Hipoteca',
      image: img_budget,
      classes: classesProd,
    },
    {
      header: 'Financiamento de Construção',
      title: 'Construa no seu terreno',
      image: img_renovation,
      classes: classesProd,
    },
    {
      header: 'Financiamento de Terreno + Construção',
      title: 'Realize o projeto dos seus sonhos',
      image: img_mortgage,
      classes: classesProd,
    },
  ],
};

// export { cardsDif, textBox };
export { cardsDif, textBox, cardsProds };
