import React from 'react';
import '../../assets/css/app/faq.css';
import Button from 'react-bootstrap/esm/Button';

export default function Faq({mobile = false}) {
  const faq = [
    {
      title: 'Como faço para comprar um imóvel?',
      href: 'como-faco-para-comprar-um-imovel',
      showMobile: false,
      content:
        'O primeiro passo para comprar um imóvel é avaliar o tipo de propriedade e a localização que atendem às suas necessidades e orçamento. Em seguida, vale a pena utilizar um simulador de financiamento bancário para entender as condições de pagamento e taxas de juros oferecidas no mercado. Após a escolha do imóvel, é importante organizar a documentação e fechar o contrato de compra e venda, assegurando-se de que todos os requisitos estejam em ordem.',
    },
    {
      title: 'Qual a documentação necessária para comprar um imóvel?',
      showMobile: false,
      href: 'qual-a-documentacao-necessaria-para-comprar-um-imovel',
      content:
        'A compra de um imóvel exige uma série de documentos importantes, incluindo: Documentos pessoais, como RG, CPF e comprovante de estado civil. Comprovantes de renda. Certidões negativas do imóvel e do vendedor. Escritura do imóvel e contrato de compra e venda. No caso de um financiamento imobiliário, podem ser solicitados documentos adicionais. É importante garantir que toda a documentação esteja completa para que o processo seja aprovado com facilidade.',
    },
    {
      title: 'Quais são as formas de pagamento disponíveis?',
      showMobile: false,
      href: 'quais-sao-as-formas-de-pagamento-disponiveis',
      content:
        'As principais formas de pagamento para imóveis incluem: Pagamento à vista, com possibilidade de descontos. Financiamento bancário, em que o banco paga o valor total ao vendedor, e o comprador reembolsa o banco em parcelas com juros. Consórcio de imóveis, onde o comprador participa de um grupo e recebe uma carta de crédito ao ser contemplado. Parcelamento direto com a construtora, disponível em alguns casos. Cada modalidade tem características próprias, e fazer simulações de financiamento pode ajudar a identificar a melhor opção.',
    },
    {
      title: 'Posso usar o FGTS para comprar um imóvel?',
      showMobile: false,
      href: 'posso-usar-o-fgts-para-comprar-um-imovel',
      content:
        'Sim, o FGTS pode ser utilizado na compra de imóveis residenciais, desde que cumpridos alguns requisitos, como ter no mínimo três anos de trabalho com recolhimento do FGTS e não possuir outro imóvel na cidade onde deseja comprar. O uso do FGTS pode ajudar a diminuir o valor financiado e, consequentemente, o valor das parcelas.',
    },
    {
      title: 'Como faço para financiar um imóvel?',
      showMobile: false,
      href: 'como-faco-para-financiar-um-imovel',
      content:
        'Para financiar um imóvel, o primeiro passo é fazer uma simulação para entender o valor das parcelas e as condições oferecidas pelos bancos. Após escolher a instituição, é necessário reunir a documentação e solicitar a análise de crédito. Trabalhar com um simulador de financiamento bancário pode ajudar a comparar as melhores condições do mercado.',
    },
    {
      title: 'Qual é o prazo máximo de um financiamento imobiliário?',
      showMobile: true,
      href: 'qual-e-o-prazo-maximo-de-um-financiamento-imobiliario',
      content:
        'No Brasil, o prazo máximo para um financiamento imobiliário costuma variar de 30 a 35 anos, conforme as políticas de cada banco. Esse prazo estendido possibilita que o comprador ajuste o valor das parcelas ao seu orçamento.',
    },
    {
      title: 'Qual é a entrada mínima para um financiamento imobiliário?',
      showMobile: false,
      href: 'qual-e-a-entrada-minima-para-um-financiamento-imobiliario',
      content:
        'A entrada mínima varia, geralmente, entre 10% e 30% do valor do imóvel, dependendo do banco e das condições do financiamento imobiliário. É interessante consultar um simulador de financiamento para calcular o valor ideal da entrada e o impacto nas parcelas mensais.',
    },
    {
      title:
        'Como saber se tenho direito ao financiamento imobiliário pelo Minha Casa Minha Vida?',
      showMobile: true,
      href: 'como-saber-se-tenho-direito-ao-financiamento-imobiliario-pelo-minha-casa-minha-vida',
      content:
        'O programa Minha Casa Minha Vida oferece condições especiais de financiamento imobiliário para famílias com renda até R$ 7.000. Para verificar se você se enquadra, é possível consultar os critérios de renda e os subsídios oferecidos pelo programa. Simulações são uma forma prática de entender os valores de entrada, juros e parcelas para esse tipo de financiamento.',
    },
    {
      title:
        'Quais são as taxas de juros atuais para financiamento imobiliário?',
      showMobile: true,
      href: 'quais-sao-as-taxas-de-juros-atuais-para-financiamento-imobiliario',
      content:
        'As taxas de juros para financiamento imobiliário variam de acordo com o banco e o perfil de crédito do cliente. Nos últimos anos, observou-se um aumento gradual nas taxas de juros, influenciado por fatores econômicos, como inflação e taxa Selic. A tendência é que essas taxas continuem elevadas ou até aumentem, dependendo da política econômica. Isso reforça a importância de fazer simulações para encontrar o melhor custo-benefício e garantir parcelas que caibam no orçamento.',
    },
    {
      title: 'Como a taxa Selic impacta o financiamento imobiliário?',
      showMobile: false,
      href: 'como-a-taxa-selic-impacta-o-financiamento-imobiliario',
      content:
        'A taxa Selic, definida pelo Banco Central, é uma referência para as taxas de juros no Brasil. Quando a Selic aumenta, o custo do crédito tende a subir, tornando o financiamento imobiliário mais caro. Com o aumento recente da Selic, muitos bancos ajustaram suas taxas de financiamento. Ficar atento a essas mudanças e buscar as melhores condições de mercado é essencial para tomar decisões informadas sobre a compra do imóvel.',
    },
    {
      title:
        'Vale a pena financiar um imóvel mesmo com o aumento das taxas de juros?',
      showMobile: false,
      href: 'vale-a-pena-financiar-um-imovel-mesmo-com-o-aumento-das-taxas-de-juros',
      content:
        'Ainda que as taxas de juros estejam em alta, o financiamento imobiliário pode ser uma boa opção para quem deseja adquirir um imóvel sem comprometer grande parte do patrimônio. Com um planejamento adequado e simulações de financiamento, é possível avaliar o impacto dos juros nas parcelas e optar por prazos e condições que se ajustem ao orçamento.',
    },
    {
      title: 'Quais são as vantagens e desvantagens de financiar um imóvel?',
      showMobile: false,
      href: 'quais-sao-as-vantagens-e-desvantagens-de-financiar-um-imovel',
      content:
        'Financiar um imóvel pode trazer diversas vantagens, como: Possibilidade de adquirir um bem de alto valor sem a necessidade de pagamento à vista. Parcelamento do valor total em prazos longos, facilitando o pagamento. Utilização do FGTS para abater o valor financiado. Por outro lado, as desvantagens incluem: Pagamento de juros ao longo do contrato, o que aumenta o custo total do financiamento. Restrições de crédito e análise de perfil financeiro. Avaliar esses pontos é fundamental para decidir se o financiamento é a melhor opção para você.',
    },
    {
      title: 'É melhor pagar aluguel ou financiar um imóvel?',
      showMobile: true,
      href: 'e-melhor-pagar-aluguel-ou-financiar-um-imovel',
      content:
        'A decisão entre pagar aluguel ou financiar um imóvel depende de diversos fatores, como: Estabilidade financeira e capacidade de pagamento. Disponibilidade de recursos para entrada e parcelas. Perspectiva de valorização do imóvel. Em geral, o financiamento imobiliário é uma opção interessante para quem deseja adquirir um imóvel próprio e investir em um patrimônio. Para quem já paga aluguel, a opção de pagar por algo prõprio é ainda mais interessante.',
    },
    {
      title: 'Qual a melhor época do ano para financiar um imóvel?',
      showMobile: false,
      href: 'qual-a-melhor-epoca-do-ano-para-financiar-um-imovel',
      content:
        'A melhor época para financiar pode variar, mas é comum que bancos ofereçam condições promocionais em momentos específicos, como feirões de imóveis e eventos do setor imobiliário. Além disso, acompanhar a tendência das taxas de juros ao longo do ano pode ajudar a identificar o momento mais vantajoso para o financiamento.',
    },
    {
      title: 'Quais são os custos adicionais ao comprar um imóvel?',
      showMobile: true,
      href: 'quais-sao-os-custos-adicionais-ao-comprar-um-imovel',
      content:
        'Além do valor do imóvel e das parcelas do financiamento, existem outros custos envolvidos, como: Taxa de avaliação e laudo técnico. Custos com escritura e registro em cartório. Imposto de Transmissão de Bens Imóveis (ITBI). Esses custos variam conforme a localidade e o valor do imóvel. Vale a pena incluí-los no planejamento financeiro para evitar surpresas.',
    },
    {
      title:
        'Como usar o simulador de financiamento bancário para planejar a compra?',
      showMobile: true,
      href: 'como-usar-o-simulador-de-financiamento-bancario-para-planejar-a-compra',
      content:
        'Um simulador de financiamento bancário é uma ferramenta útil para quem deseja planejar a compra de um imóvel. Ele permite calcular o valor das parcelas, simular diferentes prazos e verificar o impacto das taxas de juros no financiamento. Ao realizar uma simulação de financiamento em nosso site, nossa equipe irá analisar o seu perfil e apresentar as melhores opções de crédito disponíveis no mercado, verificando ainda qual é o banco mais indicado para a sua necessidade.',
    },
    {
      title: 'Quais são os cuidados ao escolher um financiamento imobiliário?',
      showMobile: false,
      href: 'quais-sao-os-cuidados-ao-escolher-um-financiamento-imobiliario',
      content:
        'Ao escolher um financiamento imobiliário, é importante estar atento a alguns cuidados, como: Comparar as taxas de juros e condições de diferentes bancos. Avaliar o valor das parcelas e o impacto no orçamento. Verificar a reputação da instituição financeira. Ler atentamente o contrato e esclarecer dúvidas antes de assinar. Com esses cuidados, é possível evitar problemas futuros e garantir um financiamento seguro.',
    },
    {
      title:
        'O que é o Sistema de Amortização Constante (SAC) no financiamento imobiliário?',
      showMobile: false,
      href: 'o-que-e-o-sistema-de-amortizacao-constante-sac-no-financiamento-imobiliario',
      content:
        'O Sistema de Amortização Constante (SAC) é uma forma de pagamento de financiamento imobiliário em que as parcelas são decrescentes ao longo do tempo. Isso significa que o valor amortizado da dívida é constante, enquanto os juros diminuem progressivamente. O SAC é uma opção interessante para quem deseja pagar menos juros ao longo do contrato.',
    },
    {
      title:
        'O que é o Sistema de Amortização Francês (Tabela Price) no financiamento imobiliário?',
      showMobile: false,
      href: 'o-que-e-o-sistema-de-amortizacao-frances-tabela-price-no-financiamento-imobiliario',
      content:
        'O Sistema de Amortização Francês, também conhecido como Tabela Price, é uma forma de pagamento de financiamento imobiliário em que as parcelas são fixas ao longo do contrato. Nesse sistema, a maior parte dos juros é paga no início do financiamento, e o valor amortizado da dívida aumenta progressivamente. A Tabela Price é uma opção interessante para quem deseja ter previsibilidade nas parcelas.',
    },
    {
      title:
        'Qual é a diferença entre o sistema SAC e o sistema Price no financiamento imobiliário?',
      showMobile: true,
      href: 'qual-e-a-diferenca-entre-o-sistema-sac-e-o-sistema-price-no-financiamento-imobiliario',
      content:
        'No financiamento imobiliário, o sistema SAC (Sistema de Amortização Constante) tem parcelas iniciais mais altas, mas que diminuem ao longo do tempo, o que pode reduzir o valor total de juros pagos. Já o sistema Price oferece parcelas fixas ao longo do contrato, mas com uma carga de juros maior no início. Avaliar essas opções e simular ambas é fundamental para escolher a modalidade mais vantajosa.',
    },
  ];

  let filteredFaq = faq;
  if (mobile) {
    filteredFaq = faq.filter((item) => item.showMobile);
  }

  return (
    <div className='setWidth my-5 faq'>
      <div className='p-4 bgContrastSoft'>
        <h1 className='fs30 py-2 fw600 faCenter textContrastColor'>
          Tudo o que você precisa saber sobre financiamento imobiliário
        </h1>
        <div className='m-3'>
          {filteredFaq.map((item, index) => (
            <div
              key={index}
              className={`my-2 p-2 hovBgContrastDark  tran05 hovPadding30 }`}
              id={item.href}
            >
              <h2 className='fs16 ffSoft textContrastColor'>{item.title}</h2>
              <h3
                className='fs12 '
                id={`content-${item.href}`}
              >
                {item.content}
              </h3>
            </div>
          ))}
          {mobile && (
            <Button
              variant='outline-primary'
              className='w100 mt-3'
              href='/perguntas-frequentes'
            >
              Acesse todas as perguntas frequentes
            </Button>
            )}
        </div>
      </div>
    </div>
  );
}
