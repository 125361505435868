import React, { useContext } from 'react';
import { images, bio, objLinks } from '../config';
import { LinkButton } from '../components/wookie';
import PackageContext from '../context/PackageContext';
import '../assets/css/app/bio.css';

export default function Bio() {
  const { SEO } = useContext(PackageContext);

  const links = () => {
    const objs = bio.items.map((item, i) => {
      return (
        <div
          key={`bio_${i}`}
          className='line bordered'
        >
          <LinkButton
            item={objLinks[item]}
            bio={true}
          />
        </div>
      );
    });
    return objs;
  };

  return (
    <div className='wrapper bio'>
      <SEO />
      <div className='cont-centered cont-400'>
        <img
          src={images.dark.logotext}
          className='mb-5 w60 mw300'
          alt='logo'
        />
        {links()}

      </div>
    </div>
  );
}
