import React from 'react';
import { ContainerBox } from '../components/wookie';
import Faq from '../components/common/Faq';
import NavBar from '../components/common/NavBar';
import Footer from '../components/common/Footer';

export default function FAQ() {
  return (
    <ContainerBox
      main={<Faq />}
      navbar={<NavBar />}
      sidebar={false}
      footer={<Footer />}
      overlayMain={false}
    />
  );
}
